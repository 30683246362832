import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Container, Typography, Button } from "@material-ui/core";
import Header from '../Header';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

const config = require('../Config/Config');

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#1b1b1b",
    padding: theme.spacing(4, 0),
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#ffffff",
    padding: theme.spacing(2),
    width: "90%",  // default for mobile
    [theme.breakpoints.up('sm')]: {
        width: "60%",  //for tablets
    },
    [theme.breakpoints.up('md')]: {
        width: "35%",  //for desktop
    },
    textAlign: "center",
    border: "2px solid #ffbb00",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  },
  inputField: {
    width: "100%",
    margin: theme.spacing(4, 0),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ffbb00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffbb00',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
  },  
  coinbaseButton: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    color: "#ffffff",
    padding: theme.spacing(1, 3),
    border: "none",
    borderRadius: theme.spacing(0.5),
    cursor: "pointer",
    width: "100%",
    textAlign: "center",
    transition: "background 0.3s ease",
    fontSize: "1.2rem",
    textTransform: "none",
    "&:hover": {
      background: "linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)",
    },
  },
  paymentButtons: {
    marginTop: theme.spacing(2),
  },
  disabledButtons: {
    marginTop: theme.spacing(2),
    opacity: "0.5",
    pointerEvents: "none",
  },
  donationMessages: {
    marginTop: theme.spacing(2),
  },
  donationProcessing: {
    backgroundColor: "#2196f3",
    color: "#ffffff",
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(0.5),
    width: "100%",
    overflow: "auto",
    textAlign: "center",
  },
  donationSuccess: {
    backgroundColor: "#4caf50",
    color: "#ffffff",
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(0.5),
    width: "100%",
    overflow: "auto",
    textAlign: "center",
  },  
}));

function Donation(props) {
  const classes = useStyles();
  
  const RATE = 2000;
  const MIN_DONATION = 1;

  const [donationValue, setDonationValue] = useState(MIN_DONATION);
  const [paymentEnabled, setPaymentEnabled] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("error");

  const displayPopup = (severity, message) => {
    setPopupSeverity(severity);
    setPopupMessage(message);
    setPopupOpen(true);
  };

  useEffect(() => {
    const CheckEnablePayment = () => 
    {
      if(donationValue < MIN_DONATION || 
        isNaN(donationValue) || 
        !props.userAuth.accountName || 
        !config.isLoggedIn(props))
        {
          setPaymentEnabled(false);
        }
        else
        {
          setPaymentEnabled(true);
        }
    };
    CheckEnablePayment();
  }, [donationValue, props]);

  const handleChangeDonation = e => {
    let value = e.target.value;
    if (value === '') 
    {
      setDonationValue('');
      return;
    }
  
    value = Number(value);
    if (!isNaN(value)) 
    {
      setDonationValue(value);
    }
  };

  const handleBlur = () => 
  {
    if (donationValue === '' || isNaN(donationValue) || donationValue < MIN_DONATION) 
    {
      setDonationValue(MIN_DONATION);
    }
  };

  const formatNumber = (number) => 
  {
    const formatter = new Intl.NumberFormat(navigator.language, 
    {
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };
  

  const createOrder = (data, actions) => {
    setPaymentProcessing(false);
    setPaymentSuccessful(false);

    if (!props.userAuth.accountName) 
    {
      // logout user
      fetch("/api/user/logout", { method: "GET" })
      .then(res => res.json())
      .then(_data => {
        props.setAuth({});
      });
      return;
    }

    return actions.order.create({
      purchase_units: [{
        custom_id: props.userAuth.accountName,
        amount: { 
          currency_code: "EUR",
          value: donationValue 
        },
      }],
    });
  };

  const onApprove = (data, actions) => {
    setPaymentProcessing(true);
  };

  const onError = (err) => {
    setPaymentProcessing(false);
    displayPopup("error", "An error occurred during the transaction. Please try again.");
    console.error(err);
  };

  const onCancel = (data) => {
    setPaymentProcessing(false);
    displayPopup("info", "Transaction cancelled.");
  };

  const handleCoinbaseDonation = () => 
  {
    setPaymentProcessing(false);
    fetch("/api/ipn/createcbcharge", 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        donationValue: donationValue,
      }),
    })
    .then(response => response.json())
    .then(data => 
    {
      const result = data.results.data.data;
      if (data && result && result.hosted_url) 
      {
        window.open(result.hosted_url, '_blank');
      }
      else
      {
        displayPopup("error", "An error occurred. Please try again.");
      }
    })
    .catch(error => 
    {
      displayPopup("error", error.message || "An error occurred. Please try again.");
    });
  };

  return (
    <Container className={classes.root}>
      <Header />
      <div className={classes.pageContainer}>
        <h1>Rates: 1 EUR = {RATE} OC</h1>
        <TextField
        label={`Donation Amount (€) Min: ${MIN_DONATION}€`}
        type="number"
        variant="outlined"
        value={donationValue}
        onChange={handleChangeDonation}
        onBlur={handleBlur}
        className={classes.inputField}
        disabled={!config.isLoggedIn(props)}
        InputProps={{ inputProps: { min: MIN_DONATION, step: 1 } }}
        />
        <h3>OC to receive: {formatNumber(parseInt(donationValue * RATE))}</h3>
        <div className={paymentEnabled ? classes.paymentButtons : classes.disabledButtons}>
          <PayPalButton 
            createOrder={createOrder} 
            onApprove={onApprove} 
            onError={onError}
            onCancel={onCancel}
          />
          <div>
          <Button
            variant="contained"
            className={classes.coinbaseButton}
            onClick={handleCoinbaseDonation}
            >
            Donate with Crypto
          </Button>

          </div>
        </div>
        <div className={classes.donationMessages}>
          {!config.isLoggedIn(props) && <Alert severity="error">You must log in to be able to donate</Alert>}
          {paymentProcessing && (
            <div className={classes.donationProcessing}>
                <Typography variant="h5" gutterBottom>
                  Donation Processing
                </Typography>
                <Typography variant="body1" paragraph>
                  Thank you for your donation! Your payment of {donationValue} EUR is being processed.
                </Typography>
                <Typography variant="body1" paragraph>
                  You will receive a confirmation email once the payment is completed.
                </Typography>
                <Typography variant="body1">
                  If you have any questions, please contact our support team.
                </Typography>
            </div>
          )}
          {paymentSuccessful && (
            <div className={classes.donationSuccess}>
                <Typography variant="h5" gutterBottom>
                  Donation Successful!
                </Typography>
                <Typography variant="body1" paragraph>
                  Thank you for your donation of {parseInt(donationValue * RATE)} OC.
                </Typography>
                <Typography variant="body1" paragraph>
                  Your payment has been successfully processed.
                </Typography>
                <Typography variant="body1">
                  If you have any questions or concerns, please contact our support team in our Discord's #support channel.
                </Typography>
            </div>
          )}
        </div>
      </div>
      <Snackbar 
        open={popupOpen} 
        autoHideDuration={5000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
            {popupMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Donation;
